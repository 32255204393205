import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "rechargePromotionAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        id: '',
        promotionName: '',
        promotionDesc: '',
        endTime: '',
        beginTime: '',
        promotionImage: '',
        imgs: [],
        items: [{
          seq: 1,
          fee: '',
          giveFee: ''
        }]
      },
      formRules: {
        promotionName: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }],
        promotionDesc: [{
          required: true,
          message: '请输入活动描述',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请输入结束时间',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请输入开始时间',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    disabledDate(v) {
      return v.getTime() < new Date().getTime() - 86400000;
    },
    changeTime(endTime) {
      if (this.formModel.beginTime === '') {
        this.formModel.endTime = '';
        ElMessage.error("请先选择开始时间！");
      } else if (endTime < this.formModel.beginTime) {
        this.formModel.endTime = '';
        ElMessage.error("结束时间不能小于开始时间！");
      }
    },
    beforeRemove() {
      this.formModel.promotionImage = null;
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true;
    },
    changeFile(file, fileList) {
      this.formModel.imgs = fileList;
      if (file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png') {
        if (file.size / 1024 > 2048) {
          ElMessage.error('图片大小不能超过2M。');
          const currIdx = this.formModel.imgs.indexOf(file);
          this.formModel.imgs.splice(currIdx, 1);
          return;
        } else {
          this.getBase64(file.raw);
        }
      } else {
        ElMessage.error('图片格式只能为【jpg、jpeg、png】');
        const currIdx = this.formModel.imgs.indexOf(file);
        this.formModel.imgs.splice(currIdx, 1);
        return;
      }
    },
    getBase64(file) {
      //把图片转成base64编码
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formModel.promotionImage = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    defaultItemChange(changeItem, v) {
      if (v) {
        // 其他改为非默认
        this.formModel.items.forEach(item => {
          if (changeItem.seq !== item.seq) {
            item.defaultItem = false;
          }
        });
      } else {
        // 第一个默认
        this.formModel.items[0].defaultItem = true;
      }
    },
    addItem() {
      this.formModel.items.push({
        seq: this.formModel.items.length + 1,
        fee: '',
        giveFee: ''
      });
    },
    deleteItem(delItem) {
      this.formModel.items.forEach(function (item, index, arr) {
        if (item.seq === delItem.seq) {
          arr.splice(index, 1);
        }
      });
      // 重新排序
      this.formModel.items.forEach((item, index) => {
        item.seq = index + 1;
      });
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.rechargePromotion.update(this.formModel).then(callBack);
          } else {
            this.$api.business.rechargePromotion.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.rechargePromotion.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          if (res.data.promotionImage) {
            this.formModel.promotionImage = res.data.promotionImage;
            this.formModel.imgs = [{
              name: 'food.jpg',
              url: res.data.promotionImage
            }];
          }
        } else if (res.code === 121701) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};